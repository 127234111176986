/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { 
  RiArrowRightSLine, 
  RiFileTextLine, 
  RiComputerLine,
  RiTeamLine, 
  RiUserSearchLine,
  RiBarChartBoxLine,
  RiBookOpenLine,
  RiHeartLine,
  RiTimeLine,
  RiCalendarCheckLine,
  RiMailLine
} from "react-icons/ri"

const ServicesPage = () => {
  return (
    <Layout>
      <SEO 
        title="HR Consulting Services | Expert Solutions for Your Organization"
        description="Comprehensive HR consulting services including policy development, software selection, performance management, recruitment, and more. Tailored solutions for businesses of all sizes."
        keywords={[
          "HR consulting services",
          "human resources solutions",
          "HR policy development",
          "HR software selection",
          "performance management consulting",
          "recruitment services",
          "employee engagement solutions"
        ]}
      />
      
      {/* Hero Section */}
      <section sx={{
        bg: 'primaryMuted',
        pt: [4, 5, 6],
        pb: [4, 5, 6],
        position: 'relative',
        overflow: 'hidden'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3,
          position: 'relative',
          zIndex: 2
        }}>
          <div sx={{
            textAlign: 'center',
            maxWidth: '900px',
            mx: 'auto',
            mb: 5
          }}>
            <h1 sx={{
              fontSize: ['2rem', '2.5rem', '3rem'],
              fontWeight: 800,
              lineHeight: 1.1,
              mb: 3,
              color: 'primary',
              letterSpacing: '-0.01em'
            }}>
              HR Consulting Services Tailored to Your Organization
            </h1>
            
            <p sx={{
              fontSize: ['1.1rem', '1.25rem'],
              lineHeight: 1.4,
              mb: 4,
              color: 'text',
              maxWidth: '800px',
              mx: 'auto'
            }}>
              Expert human resources solutions that drive compliance, performance, and positive 
              workplace culture while supporting your organization's strategic goals.
            </p>

            <div sx={{
              display: 'flex',
              gap: 3,
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}>
              <Link to="/contact" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'primary',
                color: 'white',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '1rem',
                fontWeight: 600,
                textDecoration: 'none',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                }
              }}>
                Schedule a Consultation <RiArrowRightSLine />
              </Link>
            </div>
          </div>
        </div>
      </section>
      
      {/* Services Section */}
      <section sx={{
        py: [4, 5],
        bg: 'white'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            textAlign: 'center',
            mb: 5
          }}>
            <h2 sx={{
              fontSize: ['1.75rem', '2rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              Our Comprehensive HR Solutions
            </h2>
            
            <p sx={{
              fontSize: '1.1rem',
              maxWidth: '800px',
              mx: 'auto',
              color: 'text'
            }}>
              From policy development to technology selection, we offer end-to-end 
              HR consulting services to meet your organization's unique needs.
            </p>
          </div>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)'],
            gap: 4,
            mb: 5
          }}>
            {/* HR Policy Development */}
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 12px rgba(0,0,0,0.06)',
              borderTop: '4px solid',
              borderColor: 'primary',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              transition: 'transform 0.2s, box-shadow 0.2s',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 12px 24px rgba(0,0,0,0.1)'
              }
            }}>
              <div sx={{
                color: 'primary',
                fontSize: '2.5rem',
                mb: 3
              }}>
                <RiFileTextLine />
              </div>
              
              <h3 sx={{
                fontSize: '1.3rem',
                fontWeight: 600,
                mb: 2,
                color: 'text'
              }}>
                HR Policy Development
              </h3>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 4,
                flex: 1
              }}>
                Custom policies that reflect your organization's unique needs, industry requirements, 
                and company culture while ensuring full regulatory compliance.
              </p>
              
              <Link to="/services/custom-policy-development" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: 'primary',
                fontWeight: 600,
                textDecoration: 'none',
                mt: 'auto'
              }}>
                Learn More <RiArrowRightSLine sx={{ ml: 1 }} />
              </Link>
            </div>
            
            {/* HR Software Selection */}
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 12px rgba(0,0,0,0.06)',
              borderTop: '4px solid',
              borderColor: 'primary',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              transition: 'transform 0.2s, box-shadow 0.2s',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 12px 24px rgba(0,0,0,0.1)'
              }
            }}>
              <div sx={{
                color: 'primary',
                fontSize: '2.5rem',
                mb: 3
              }}>
                <RiComputerLine />
              </div>
              
              <h3 sx={{
                fontSize: '1.3rem',
                fontWeight: 600,
                mb: 2,
                color: 'text'
              }}>
                HR Software Selection
              </h3>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 4,
                flex: 1
              }}>
                Vendor-neutral guidance through the complex HR technology landscape to find solutions 
                that truly fit your organization's needs and budget.
              </p>
              
              <Link to="/services/hr-software-selection" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: 'primary',
                fontWeight: 600,
                textDecoration: 'none',
                mt: 'auto'
              }}>
                Learn More <RiArrowRightSLine sx={{ ml: 1 }} />
              </Link>
            </div>
            
            {/* Performance Management */}
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 12px rgba(0,0,0,0.06)',
              borderTop: '4px solid',
              borderColor: 'primary',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              transition: 'transform 0.2s, box-shadow 0.2s',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 12px 24px rgba(0,0,0,0.1)'
              }
            }}>
              <div sx={{
                color: 'primary',
                fontSize: '2.5rem',
                mb: 3
              }}>
                <RiBarChartBoxLine />
              </div>
              
              <h3 sx={{
                fontSize: '1.3rem',
                fontWeight: 600,
                mb: 2,
                color: 'text'
              }}>
                Performance Management
              </h3>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 4,
                flex: 1
              }}>
                Design and implementation of performance review processes that drive employee 
                development, engagement, and organizational success.
              </p>
              
              <Link to="/services/performance-management" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: 'primary',
                fontWeight: 600,
                textDecoration: 'none',
                mt: 'auto'
              }}>
                Learn More <RiArrowRightSLine sx={{ ml: 1 }} />
              </Link>
            </div>
            
            {/* Recruitment & Selection */}
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 12px rgba(0,0,0,0.06)',
              borderTop: '4px solid',
              borderColor: 'primary',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              transition: 'transform 0.2s, box-shadow 0.2s',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 12px 24px rgba(0,0,0,0.1)'
              }
            }}>
              <div sx={{
                color: 'primary',
                fontSize: '2.5rem',
                mb: 3
              }}>
                <RiUserSearchLine />
              </div>
              
              <h3 sx={{
                fontSize: '1.3rem',
                fontWeight: 600,
                mb: 2,
                color: 'text'
              }}>
                Recruitment & Selection
              </h3>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 4,
                flex: 1
              }}>
                Strategic talent acquisition services that help you attract, evaluate, and select 
                the right candidates for your organization's culture and needs.
              </p>
              
              <Link to="/services/recruitment-selection" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: 'primary',
                fontWeight: 600,
                textDecoration: 'none',
                mt: 'auto'
              }}>
                Learn More <RiArrowRightSLine sx={{ ml: 1 }} />
              </Link>
            </div>
            
            {/* Learning & Development */}
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 12px rgba(0,0,0,0.06)',
              borderTop: '4px solid',
              borderColor: 'primary',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              transition: 'transform 0.2s, box-shadow 0.2s',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 12px 24px rgba(0,0,0,0.1)'
              }
            }}>
              <div sx={{
                color: 'primary',
                fontSize: '2.5rem',
                mb: 3
              }}>
                <RiBookOpenLine />
              </div>
              
              <h3 sx={{
                fontSize: '1.3rem',
                fontWeight: 600,
                mb: 2,
                color: 'text'
              }}>
                Learning & Development
              </h3>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 4,
                flex: 1
              }}>
                Custom training programs and development frameworks that build your workforce 
                capabilities and prepare your organization for future challenges.
              </p>
              
              <Link to="/services/learning-development" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: 'primary',
                fontWeight: 600,
                textDecoration: 'none',
                mt: 'auto'
              }}>
                Learn More <RiArrowRightSLine sx={{ ml: 1 }} />
              </Link>
            </div>
            
            {/* Employee Engagement */}
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 12px rgba(0,0,0,0.06)',
              borderTop: '4px solid',
              borderColor: 'primary',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              transition: 'transform 0.2s, box-shadow 0.2s',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 12px 24px rgba(0,0,0,0.1)'
              }
            }}>
              <div sx={{
                color: 'primary',
                fontSize: '2.5rem',
                mb: 3
              }}>
                <RiHeartLine />
              </div>
              
              <h3 sx={{
                fontSize: '1.3rem',
                fontWeight: 600,
                mb: 2,
                color: 'text'
              }}>
                Employee Engagement
              </h3>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 4,
                flex: 1
              }}>
                Strategies and programs that boost employee satisfaction, productivity, and retention 
                by fostering a positive workplace culture and sense of purpose.
              </p>
              
              <Link to="/services/employee-engagement" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: 'primary',
                fontWeight: 600,
                textDecoration: 'none',
                mt: 'auto'
              }}>
                Learn More <RiArrowRightSLine sx={{ ml: 1 }} />
              </Link>
            </div>
          </div>
        </div>
      </section>
      
      {/* Why Choose Us Section */}
      <section sx={{
        py: [4, 5],
        bg: '#f8f9fa'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            textAlign: 'center',
            mb: 5
          }}>
            <h2 sx={{
              fontSize: ['1.75rem', '2rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              Why Choose Our HR Consulting Services
            </h2>
          </div>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(4, 1fr)'],
            gap: 4,
            mb: 4
          }}>
            <div sx={{
              textAlign: 'center',
              p: 3
            }}>
              <div sx={{ 
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80px',
                height: '80px',
                borderRadius: '50%',
                bg: 'primaryMuted',
                mx: 'auto',
                mb: 3
              }}>
                <RiTeamLine size={36} sx={{ color: 'primary' }} />
              </div>
              <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                Experienced Consultants
              </h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                Our team brings decades of HR experience across industries, providing practical 
                solutions that work in the real world.
              </p>
            </div>
            
            <div sx={{
              textAlign: 'center',
              p: 3
            }}>
              <div sx={{ 
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80px',
                height: '80px',
                borderRadius: '50%',
                bg: 'primaryMuted',
                mx: 'auto',
                mb: 3
              }}>
                <RiFileTextLine size={36} sx={{ color: 'primary' }} />
              </div>
              <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                Customized Approach
              </h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                We tailor our services to your specific needs rather than applying 
                one-size-fits-all solutions.
              </p>
            </div>
            
            <div sx={{
              textAlign: 'center',
              p: 3
            }}>
              <div sx={{ 
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80px',
                height: '80px',
                borderRadius: '50%',
                bg: 'primaryMuted',
                mx: 'auto',
                mb: 3
              }}>
                <RiTimeLine size={36} sx={{ color: 'primary' }} />
              </div>
              <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                Efficient Implementation
              </h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                We focus on practical, actionable solutions that can be implemented 
                quickly to deliver immediate value.
              </p>
            </div>
            
            <div sx={{
              textAlign: 'center',
              p: 3
            }}>
              <div sx={{ 
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80px',
                height: '80px',
                borderRadius: '50%',
                bg: 'primaryMuted',
                mx: 'auto',
                mb: 3
              }}>
                <RiBarChartBoxLine size={36} sx={{ color: 'primary' }} />
              </div>
              <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                Results-Driven
              </h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                Our services are designed to deliver measurable improvements in compliance, 
                efficiency, and employee experience.
              </p>
            </div>
          </div>
        </div>
      </section>
      
      {/* CTA Section */}
      <section sx={{
        bg: 'primary',
        py: [4, 5],
        mt: 4
      }}>
        <div sx={{
          maxWidth: '1000px',
          mx: 'auto',
          px: 3,
          textAlign: 'center'
        }}>
          <h2 sx={{
            fontSize: ['1.8rem', '2.3rem'],
            fontWeight: 700,
            color: 'white',
            mb: 2
          }}>
            Ready to Transform Your HR Practices?
          </h2>
          
          <p sx={{
            fontSize: ['1rem', '1.2rem'],
            color: 'white',
            mb: [3, 4],
            lineHeight: 1.6,
            maxWidth: '800px',
            mx: 'auto',
            opacity: 0.9
          }}>
            Schedule a consultation with our HR experts to discuss your organization's
            unique needs and learn how we can help you build more effective HR practices.
          </p>
          
          <div sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
            gap: 4,
            justifyContent: 'center',
            maxWidth: '600px',
            mx: 'auto'
          }}>
            <Link to="/contact" sx={{
              bg: 'white',
              color: 'primary',
              py: '12px',
              px: '24px',
              borderRadius: '5px',
              fontWeight: 600,
              textDecoration: 'none',
              fontSize: '1rem',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'transform 0.2s, box-shadow 0.2s',
              ':hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 20px rgba(0,0,0,0.2)'
              }
            }}>
              Schedule a Consultation <RiCalendarCheckLine sx={{ ml: 2 }} />
            </Link>
            
            <Link to="/contact" sx={{
              bg: 'transparent',
              color: 'white',
              py: '12px',
              px: '24px',
              borderRadius: '5px',
              fontWeight: 600,
              textDecoration: 'none',
              fontSize: '1rem',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid white',
              transition: 'transform 0.2s, box-shadow 0.2s, background-color 0.2s',
              ':hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 20px rgba(0,0,0,0.2)',
                bg: 'rgba(255,255,255,0.1)'
              }
            }}>
              Contact Us <RiMailLine sx={{ ml: 2 }} />
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ServicesPage; 